import ThemeContainer from '../components/ThemeContainer';
import { useEffect, useLayoutEffect, useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import configData from '../config';
import axios from 'axios';
import i18next from '../i18n';
import { useTranslation } from 'react-i18next'
import "./Sorry.scss"
const Sorry = () => {
    const { t, i18n } = useTranslation('sorry')
    const { lang, id } = useParams();

    return (
        <ThemeContainer>
            <div className="flex flex-col Sorry justify-center absolute inset-0 p-8">
                <div className="text-center font-bold space-y-8">
                    <img src="/sorry.png"  alt="sorry" className="mx-auto img-sorry"/>
                    <span className="green-text block text-3xl">{t('sorry')}</span>
                    <span className="desc block text-black whitespace-pre-line" >{t('desc')}</span>
                    {/* <div className="redeemcode w-full py-4">
                        <span className="green-text">{redeemCode}</span>
                    </div> */}
                </div>
            </div>
        </ThemeContainer>
    );
}
export default Sorry