import i18n from 'i18next'
import { initReactI18next } from 'react-i18next';

//  locales
import en from './locale/en'
import zhHK from './locale/zh-hk'
import zhCN from './locale/zh-cn'

const resources = {
    en,
    zh: zhHK,
    sc: zhCN,
}

i18n
    .use(initReactI18next)
    .init({
        compatibilityJSON: 'v3',
        fallbackLng: 'en',
        lng: 'en',
        resources,
        interpolation: {
            escapeValue: false,
        },
        ns: ['common'],
        defaultNS: 'common',
    });

export default i18n
