// Library
import { useState, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { get, snakeCase } from 'lodash'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

// Component
import ProductImage from '../components/ProductImage'
import StyledLoadingButton from '../components/common/StyledLoadingButton'

// Action
import { getProductImageUrl }from '../helpers'

export default function Home() {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation('home')
    const { lang, id } = useParams();
    const { incentStatus } = useSelector(state => state.incent)
    const [loading, setLoading] = useState(false)

    const productName = useMemo(() => {
        return get(incentStatus, `redeem_product.name_${snakeCase(i18n.language)}`, '')
    }, [incentStatus, i18n.language])

    return (
        <div className="Home space-y-8">
            <img src="/incent_logo.png"  alt="Incent App Log" className="mx-auto m-4"/>
            <ProductImage productImage={getProductImageUrl(get(incentStatus, `redeem_product.id`, null))} />
            <span className="text-center text-gray-400 block">{productName}</span>
            <h1 className="header text-4xl	text-black text-center w-60 mx-auto pt-6" dangerouslySetInnerHTML={{ __html: t('sponsor') }}></h1>
            <StyledLoadingButton
                loading={loading}
                variant="contained"
                onClick={() => {navigate( incentStatus?.status === 1 ? `/${lang}/${id}/kudos`:`/${lang}/${id}/filter`)}}
            >{ t('getSponsor') }</StyledLoadingButton>
        </div>
    );
}
