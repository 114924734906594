// Library
import {useEffect, useContext, useState, useLayoutEffect } from "react"
import {AppDataContext} from '../context/AppDataContext';
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios"
import i18n from 'i18next';
import { useTranslation } from 'react-i18next'
import { mapValues, get } from 'lodash'
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from 'react-redux'

// Component
import FormContainer from "../components/form/Container";
import ThemeContainer from "../components/ThemeContainer";
import ProductImage from "../components/ProductImage";
import configData from "../config.js"
import getPersonalDetailsConfig from '../components/form/config/personalDetails'
import {UpdateLocaleByLang, getProductImageUrl} from "../helpers"
import { setIncentPersonalDetailFormData, setIncentStatus } from "../redux/actions/incent";

export default function PersonalDetails() {
    const { register, handleSubmit, watch, formState: { errors }, setValue, control, getValues } = useForm({shouldUnregister: false});
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const {appDataDispatch} = useContext(AppDataContext)
    const [loading, setLoading] = useState(false)
    const  [productName, setProductName] = useState()
    const [personalDetailsFormConfig, setPersonalDetailsFormConfig] = useState([])
    const [error, setError] = useState();
    const { t } = useTranslation('reg')
    const { selected_sponsor, sponsor_service_details } = useSelector(state => state.sponsor)
    const { incentFormConfig, incentStatus, incentFilterFormData } = useSelector(state => state.incent)
    const ShowError = () => {
        if (error) return (<div className="text-red-500 text-center">{error}</div>)
        return (<></>)
    }

    let { lang, id } = useParams();
    useEffect(() => {
        UpdateLocaleByLang(lang)
    }, [lang])

    useEffect(()=> {
        if(!incentFilterFormData || !selected_sponsor) {
            navigate(`/${lang}/${id}`)
        }
    }, [incentFilterFormData, selected_sponsor])

    useEffect(() => {
        if(!incentStatus) {
            console.log('No incent status, redirect to home')
            navigate(`/${lang}/${id}`)
        }
    }, [incentStatus, incentFormConfig])
    
    useLayoutEffect(()=>{
        if(sponsor_service_details && selected_sponsor && incentStatus.incent_form){

            let interest_options = []
            selected_sponsor.forEach((sponsorId) => {
                sponsor_service_details[sponsorId].services.forEach((config)=> {
                    interest_options.push({
                        label: {
                            en: config.name.en,
                            zh: config.name.zh,
                            sc: config.name.cn
                        },
                        desc:  {
                            en: config.description.en,
                            zh: config.description.zh,
                            sc: config.description.cn
                        },
                        value: config.id,
                        name: "brand-showcase"
                    })
                })  
            })
            setPersonalDetailsFormConfig(
                getPersonalDetailsConfig({...incentStatus.incent_form ,interestOptions: interest_options})
            )
        }
    }, [sponsor_service_details, selected_sponsor,incentStatus])

    useEffect(()=>{
        appDataDispatch({
            type: 'set_id',
            value: id
        })
    },[])
    const onSubmit = async (data, event) => {
        let submitData = mapValues(data, (item)=>(
            item.value || item
        ))
        let country_code 
        switch(process.env.REACT_APP_LOCATION.toLowerCase()){
            case 'hk':
                country_code = '+852'
                break
            case 'sg':
                country_code = '+65'
                break
            case 'my':
                country_code = '+60'
                break
            default:
                country_code = ''
                break
        }

        submitData['phone_number'] = `${country_code}${submitData['phone_number']}`
        submitData['incentFormSubmissionId'] = id
        event.preventDefault()
        setLoading(true)
    
        dispatch(setIncentPersonalDetailFormData(submitData))
          
        await axios.patch(
            `${configData.dashboard_api_endpoint}/incent/incent_form_submissions/${id}`,
            {...submitData, ...incentFilterFormData},
            { headers: { 'Content-Type': 'application/json'}}
        ).then((response) => {
            dispatch(setIncentStatus(null))
            if(incentStatus?.incent_form?.requires_phone_verification || incentStatus?.incent_form?.requires_email_verification){
                navigate(`/${lang}/${id}/verify`)
            }else{
                navigate(`/${lang}/${id}/kudos`)
            }
        }).catch((err) => {
            if(err.response.data.data === "Form is not pending for submission"){
                // resendVerification(data)
                navigate(`/${lang}/${id}/sorry`)
            }else{
                switch (err.response.status) {
                    case 400:
                        setError(t('form.error.submitted'))
                        break   
                    default:
                        setError(err.message || err.data)
                }
            }
        }).finally(()=>{
            setLoading(false)
        })
    }

    return (
        <div className='personal_details space-y-4 pt-4'> 
            <div className="header text-black">{t('heading')}</div>
            <ProductImage productImage={getProductImageUrl(get(incentStatus, `redeem_product.id`, null))}  by={sponsor_service_details?.[selected_sponsor[0]]?.['logo']}/>
            <FormContainer onSubmit={onSubmit} formConfig={Object.values(personalDetailsFormConfig)} loading={loading}/>
            <ShowError />
        </div>
    );
}
