// Library
import { forwardRef } from 'react';

// Component
import Container from './Container.js'
import TextField from '@mui/material/TextField';

const Input = forwardRef(({ placeholder, type, onChange, onBlur, name, label, errorMsg, setValue, control, icon, inputProps={} }, ref) => {
    return <Container label={label} errorMsg={errorMsg} icon={icon}>
        {
            <TextField
                error={!!errorMsg}
                helperText={errorMsg}
                label={placeholder}
                type={type}
                className="placeholder:text-gray-400 text-black w-full"
                name={name}
                inputRef={ref}
                onChange={onChange}
                onBlur={onBlur}
                variant="standard"
                InputProps={{ disableUnderline: true}}
                inputProps={{...inputProps}}
            />
        }
    </Container>
  });
export default Input