// const PersonalDetails = [
//     {
//         type: 'text',
//         name: 'name',
//         icon: 'profile',
//         label: {
//             en: 'Name',
//             'zh-hk': '姓名',
//             sc: '姓名'
//         },
//         desc: {
//             en: 'Please input your name.',
//             'zh-hk': '請輸入你的姓名',
//             sc: '请输入你的姓名',
//         },
//         validation: [
//             {
//                 type: 'required',
//                 error: {
//                     en: 'Please enter your name',
//                     'zh-hk': '請輸入姓名',
//                     sc: '请输入姓名'
//                 }
//             },
//         ],
//     },
//     // {
//     //     type: 'text',
//     //     name: 'email',  
//     //     label: {
//     //         en: 'Email',
//     //         'zh-hk': '電郵',
//     //         sc: '电邮'
//     //     },
//     //     desc: {
//     //         en: 'Please input your email.',
//     //         'zh-hk': '請輸入你的電郵地址',
//     //         sc: '请输入你的电邮地址'
//     //     },
//     //     validation: [
//     //         {
//     //             type: 'required',
//     //             error: {
//     //                 en: 'Please enter your email',
//     //                 'zh-hk': '請輸入你的電郵地址',
//     //                 sc: '请输入你的电邮地址'
//     //             }
//     //         },
//     //     ],
//     // },
//     {
//         type: 'tel',
//         name: 'phone',  
//         icon: 'message',
//         label: {
//             en: 'Phone (for SMS)',
//             'zh-hk': '電話號碼 (用作收驗證碼)',
//             sc: '电话号码 (用作收验证码)'
//         },
//         // desc: {
//         //     en: 'Please enter your mobile number.',
//         //     'zh-hk': '請輸入你的電話號碼',
//         //     sc: '请输入你的电话号码'
//         // },
//         validation: [
//             {
//                 type: 'pattern',
//                 value: /^[+]\d{10,}$/,
//                 error: {
//                     en: 'Please enter your mobile number',
//                     'zh-hk': '請輸入電話號碼',
//                     sc: '请输入电话号码'
//                 }
//             },
//         ]
//     },
//     {
//         type: 'select',
//         name: 'interested',  
//         icon: 'interest',

//         label: {
//             en: 'Your Interest',
//             'zh-hk': '感興趣的服務',
//             sc: '感兴趣的服务'
//         },
//         options: [
//             {
//                 label: {
//                     en: 'Brand Showcase',
//                     'zh-hk': '小學英文補習免費試堂',
//                     sc: '小學英文補習免費試堂'
//                 },
//                 desc:  {
//                     en: '',
//                     'zh-hk': '由 英群補習社（上環分店） 提供',
//                     sc: '由 英群補習社（上環分店） 提供'
//                 },
//                 value: "brand-showcase",
//                 name: "brand-showcase"
//             },
//             {
//                 label: {
//                     en: 'Campaign Showcase',
//                     'zh-hk': '小學中文補習免費試堂',
//                     sc: '小學中文補習免費試堂'
//                 },
//                 desc:  {
//                     en: '',
//                     'zh-hk': '由 英群補習社（上環分店） 提供',
//                     sc: '由 英群補習社（上環分店） 提供'
//                 },
//                 value: "campaign-showcase",
//                 name: "campaign-showcase",
//             },
//             {
//                 label: {
//                     en: 'Passive Income',
//                     'zh-hk': '免費專業皮膚分析一次',
//                     sc: '免費專業皮膚分析一次'
//                 },
//                 desc:  {
//                     en: '',
//                     'zh-hk': '由 BeautyZ (沙田分店) 提供',
//                     sc: '由 BeautyZ (沙田分店) 提供'
//                 },
//                 value: "passive-income",
//                 name: "passive-income",
//             }
//             // {
//             //     label: {
//             //         en: '90分鐘 任食邊爐 $50 現金券',
//             //         'zh-hk': '90分鐘 任食邊爐 $50 現金券',
//             //         sc: '90分鐘 任食邊爐 $50 現金券'
//             //     },
//             //     desc:  {
//             //         en: '',
//             //         'zh-hk': '由 任食火鍋店 （沙田分店） 提供',
//             //         sc: '由 任食火鍋店 （沙田分店） 提供'
//             //     },
//             //     value: "coupon-hotpot",
//             //     name: "coupon-hotpot",
//             // },
//         ],
//         validation: [
//             {
//                 type: 'required',
//                 error: {
//                     en: 'Please choose your interested Service / Product',
//                     'zh-hk': '請選擇',
//                     sc: '请选择'
//                 }
//             },
//         ]
//     },
//     {
//         type: 'checkbox',
//         name: 'marketing',
//         label: {
//             en: 'I understand that “Incent” will need to share the data with the brand/service provider to enjoy the sponsorship. I hereby agree to receiving market promotions and sales information sent by “Incent” and its partners.',
//             'zh-hk': '我理解 INCENT 需要與品牌或服務供應商分享數據，才能取得免費產品或優惠。我在此同意接收由 INCENT 及其合作夥伴所發送有關市場推廣及銷售信息的相關內容。',
//             sc: '我理解 INCENT 需要与品牌或服务供应商分享数据，才能取得免费产品或优惠。我在此同意接收由 INCENT 及其合作伙伴所发送有关市场推广及销售信息的相关内容。'
//         },
//         validation: []
//     },
//     {
//         type: 'checkbox',
//         name: 'tnc',
//         label: {
//             en: 'I hereby agree to and accept the Terms and Privacy Policy.',
//             'zh-hk': '我在此同意並接受條款和隱私政策。',
//             sc: '我在此同意并接受条款和隐私政策。'
//         },
//         validation: []
//     },
// ]

const PersonalDetails = ({contains_name, contains_phone_number, contains_email, interestOptions, requires_email_verification, requires_phone_verification}) => {
    console.log('requires_email_verification',requires_email_verification, 'requires_phone_verification', requires_phone_verification)
    let phone_number_regex, country_name
    // switch config set based on  REACT_APP_LOCATION
    switch(process.env.REACT_APP_LOCATION.toLowerCase()){
        case 'hk':
            phone_number_regex = /^[0-9]{8}$/
            country_name = {
                en: 'Hong Kong',
                zh: '香港',
                sc: '香港'
            }
            break
        case 'sg':
            phone_number_regex = /^[0-9]{8}$/
            country_name = {
                en: 'Singapore',
                zh: '新加坡',
                sc: '新加坡'
            }
            break
        case 'my':
            phone_number_regex = /^[0-9]{8,10}$/
            country_name = {
                en: 'Malaysia',
                zh: '馬來西亞',
                sc: '马来西亚'
            }
            break
        default: 
            phone_number_regex = /^[0-9]{5,}$/
            country_name = {
                en: '',
                zh: '',
                sc: ''
            }
            break
    }
    let formFields = []
    formFields.push({
        type: 'text',
        name: 'name',
        icon: 'profile',
        label: {

            en: 'Name',
            'zh-hk': '姓名',
            sc: '姓名'
        },
        desc: {
            en: 'Please input your name.',
            'zh-hk': '請輸入你的姓名',
            sc: '请输入你的姓名',
        },
        validation: [
            contains_name && {
                type: 'required',
                error: {
                    en: 'Please enter your name',
                    'zh-hk': '請輸入姓名',
                    sc: '请输入姓名'
                }
            },
        ],
    });
    formFields.push({
        type: 'tel',
        name: 'phone_number',  
        icon: 'message',
        label: {
            en: 'Phone (for SMS)',
            'zh-hk': '電話號碼 (用作收驗證碼)',
            sc: '电话号码 (用作收验证码)'
        },
        validation: [

            contains_phone_number && {
                type: 'required',
                error: {
                    en: 'Please enter your mobile number',
                    'zh-hk': '請輸入電話號碼',
                    sc: '请输入电话号码'
                }
            },
            {
                type: 'pattern',
                value: phone_number_regex,
                error: {
                    en: `Please enter a valid ${country_name.en} mobile number`,
                    'zh-hk': `請輸入正確的${country_name.zh}電話號碼`,
                    sc: `请输入正确的${country_name.sc}电话号码`
                }
            },
        ]
    })
    formFields.push({
        type: 'text',
        name: 'email',  
        icon: 'message',
        label: {
            en: 'Email Address',
            'zh-hk': '電郵地址',
            sc: '电邮地址'
        },
        validation: [

            (contains_email && {
                type: 'required',
                error: {
                    en: 'Please enter your email address',
                    'zh-hk': '請輸入電郵地址',
                    sc: '请输入电邮地址'
                }
            }),
            {
                type: 'pattern',
                value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                error: {
                    en: 'Please enter a valid email address',
                    'zh-hk': '請輸入正確的電郵地址',
                    sc: '请输入正确的电邮地址'
                }
            },
        ]
    })
    formFields.push({
        type: 'select',
        name: 'incent_sponsor_service_id',  
        icon: 'interest',

        label: {
            en: 'Your Interest',
            'zh-hk': '感興趣的服務',
            sc: '感兴趣的服务'
        },
        options: interestOptions,
        validation: [
            {
                type: 'required',
                error: {
                    en: 'Please choose your interested Service / Product',
                    'zh-hk': '請選擇',
                    sc: '请选择'
                }
            },
        ]
    })
    formFields.push({
        type: 'checkbox',
        name: 'opt_in_for_marketing',
        label: {
            en: 'I understand that “Incent” will need to share the data with the brand/service provider to enjoy the sponsorship. I hereby agree to receiving market promotions and sales information sent by “Incent” and its partners.',
            'zh-hk': '我理解 INCENT 需要與品牌或服務供應商分享數據，才能取得免費產品或優惠。我在此同意接收由 INCENT 及其合作夥伴所發送有關市場推廣及銷售信息的相關內容。',
            sc: '我理解 INCENT 需要与品牌或服务供应商分享数据，才能取得免费产品或优惠。我在此同意接收由 INCENT 及其合作伙伴所发送有关市场推广及销售信息的相关内容。'
        },
        validation: [ {
            type: 'required',
            error: {
                en: 'Please check',
                'zh-hk': '請剔選',
                sc: '请剔选',
            }
        }]
    })
    formFields.push({
        type: 'checkbox',
        name: 'opt_in_for_terms',
        label: {
            en: 'I hereby agree to and accept the Terms and Privacy Policy.',
            'zh-hk': '我在此同意並接受條款和隱私政策。',
            sc: '我在此同意并接受条款和隐私政策。'
        },
        validation: [ {
            type: 'required',
            error: {
                en: 'Please check',
                'zh-hk': '請剔選',
                sc: '请剔选'
            }
        }]
    })
    return formFields;
}

export default PersonalDetails