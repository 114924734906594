import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import FormContainer from "../components/form/Container";
import axios from 'axios';
import { useTranslation } from 'react-i18next'
import PopupModal from '../components/common/PopupModal'
import configData from '../config';
import {lowerCase, mapValues} from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import { setIncentStatus } from '../redux/actions/incent'

const WaitList = () => {
    const { t } = useTranslation('waitList')
    const { lang, id } = useParams();
    const [showThankYou, setShowThankYou] = useState(false)
    const { incentFilterFormData, incentStatus } = useSelector(state => state.incent)
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const handleSubmit = async (data, event) => {
        let submitData = mapValues(data, (item)=>(
            item.value || item
        ))
        let country_code 
        switch(process.env.REACT_APP_LOCATION.toLowerCase()){
            case 'hk':
                country_code = '+852'
                break
            case 'sg':
                country_code = '+65'
                break
            case 'my':
                country_code = '+60'
                break
            default:
                country_code = ''
                break
        }

        submitData['phone_number'] = `${country_code}${submitData['phone_number']}`
        await axios.patch(
            `${configData.dashboard_api_endpoint}/incent/incent_form_submissions/${id}`,
            {...submitData, ...incentFilterFormData, ...{is_waitlist: 1}},
            { headers: { 'Content-Type': 'application/json'}}
        ).then((response) => {
            if(incentStatus?.incent_form?.requires_phone_verification || incentStatus?.incent_form?.requires_email_verification){
                dispatch(setIncentStatus(null))
                navigate(`/${lang}/${id}/verify`)
            }else{
                setShowThankYou(true)
            }
        }).catch((err) => {
            console.log('err', err)
            navigate(`/${lang}/${id}/sorry`)
        })
    }
      
    const handleClose = () => {
        setShowThankYou(false)
    }

    let phone_number_regex, country_name = ''
    // switch config set based on  REACT_APP_LOCATION
    switch(lowerCase(process.env.REACT_APP_LOCATION)){
        case 'hk':
            phone_number_regex = /^[0-9]{8}$/
            country_name = {
                en: 'Hong Kong',
                zh: '香港',
                sc: '香港'
            }
            break
        case 'sg':
            phone_number_regex = /^[0-9]{8}$/
            country_name = {
                en: 'Singapore',
                zh: '新加坡',
                sc: '新加坡'
            }
            break
        case 'my':
            phone_number_regex = /^[0-9]{8,10}$/
            country_name = {
                en: 'Malaysia',
                zh: '馬來西亞',
                sc: '马来西亚'
            }
            break
        default: 
            phone_number_regex = /^[0-9]{5,}$/
            country_name = {
                en: '',
                zh: '',
                sc: ''
            }
            break
    }

    return (
        <>
            <div className="flex flex-col WaitList justify-center inset-0 p-8">
                <div className="text-center font-bold space-y-4 text-black">
                    <img src="/love.png"  alt="wait list" className="mx-auto m-4"/>
                    <span className="heading block ">{t('heading')}</span>
                    <div className="desc w-full py-4 text-xs whitespace-pre-line">{t('desc')}</div>
                    <div className="text-left">
                        <FormContainer 
                            onSubmit={handleSubmit} 
                            formConfig={[{
                                type: 'tel',
                                name: 'phone_number',  
                                icon: 'message',
                                label: {
                                    en: 'Phone (for SMS)',
                                    'zh-hk': '電話號碼 (用作收驗證碼)',
                                    sc: '电话号码 (用作收验证码)'
                                },
                                validation: [
                                    {
                                        type: 'required',
                                        error: {
                                            en: 'Please enter your mobile number',
                                            'zh-hk': '請輸入電話號碼',
                                            sc: '请输入电话号码'
                                        }
                                    },
                                    {
                                        type: 'pattern',
                                        value: phone_number_regex,
                                        error: {
                                            en: `Please enter a valid ${country_name.en} mobile number`,
                                            'zh-hk': `請輸入正確的${country_name.zh}電話號碼`,
                                            sc: `请输入正确的${country_name.sc}电话号码`
                                        }
                                    },
                                ]
                            },
                            {
                                type: 'text',
                                name: 'email',  
                                icon: 'message',
                                label: {
                                    en: 'Email Address',
                                    'zh-hk': '電郵地址',
                                    sc: '电邮地址'
                                },
                                validation: [                
                                    {
                                        type: 'required',
                                        error: {
                                            en: 'Please enter your email address',
                                            'zh-hk': '請輸入電郵地址',
                                            sc: '请输入电邮地址'
                                        }
                                    },
                                    {
                                        type: 'pattern',
                                        value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                        error: {
                                            en: 'Please enter a valid email address',
                                            'zh-hk': '請輸入正確的電郵地址',
                                            sc: '请输入正确的电邮地址'
                                        }
                                    },
                                ]
                            },
                            {
                                type: 'checkbox',
                                name: 'opt_in_for_marketing',
                                label: {
                                    en: 'I understand that “Incent” will need to share the data with the brand/service provider to enjoy the sponsorship. I hereby agree to receiving market promotions and sales information sent by “Incent” and its partners.',
                                    'zh-hk': '我理解 INCENT 需要與品牌或服務供應商分享數據，才能取得免費產品或優惠。我在此同意接收由 INCENT 及其合作夥伴所發送有關市場推廣及銷售信息的相關內容。',
                                    sc: '我理解 INCENT 需要与品牌或服务供应商分享数据，才能取得免费产品或优惠。我在此同意接收由 INCENT 及其合作伙伴所发送有关市场推广及销售信息的相关内容。'
                                },
                                validation: [ {
                                    type: 'required',
                                    error: {
                                        en: 'Please check',
                                        'zh-hk': '請剔選',
                                        sc: '请剔选',
                                    }
                                }]
                            },
                            {
                                type: 'checkbox',
                                name: 'opt_in_for_terms',
                                label: {
                                    en: "I hereby agree to and accept the Terms and Privacy Policy.",
                                    'zh-hk': '我在此同意並接受條款和隱私政策。',
                                    sc: '我在此同意并接受条款和隐私政策。'
                                },
                                validation: [ {
                                    type: 'required',
                                    error: {
                                        en: 'Please check',
                                        'zh-hk': '請剔選',
                                        sc: '请剔选'
                                    }
                                }]
                            },]} 
                            submitBtnTitle={t('common:submit')}
                        />
                    </div>
                </div>
            </div>
            <PopupModal 
                open={showThankYou}
                onClose={handleClose}
                title={t('thankYouModal.title')}
                description={t('thankYouModal.desc')}
                submitBtnTitle='OK'
                submitBtnOnClick={handleClose}
            /> 
        </>
    );
}
export default WaitList