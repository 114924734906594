import './productImage.scss'
const ProductImage = (props) => {
    return <div className="product-image-container relative my-5 min-h-20">
        <img src="/product_bg.png" alt="product background" className="mx-auto absolute bottom-0 left-0 right-0 z-0" />
        <img src={props.productImage} alt="product" className="mx-auto relative z-20 w-32 pb-4 product-image"/>
        {props.by 
            && 
        <div className="sponsor-container absolute bottom-4 mx-auto text-black left-0 right-0 w-fit py-2 px-6 z-30 text-xs">
            by  <img className="text-xs text-white h-3 inline" src={props.by} alt="sponsor by"/>
        </div>}
    </div>
}

export default ProductImage