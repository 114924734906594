import { SET_SELECTED_SPONSOR, SET_SPONSOR_SERVICE_DETAILS } from '../types'

const initialState = {
    selected_sponsor: null,
    sponsor_service_details: null,
}

const sponsorReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SELECTED_SPONSOR:
            console.log('SET_SELECTED_SPONSOR', state, action)
            return {
                ...state,
                selected_sponsor: action.selected_sponsor
            }
        case SET_SPONSOR_SERVICE_DETAILS:{
            return {
                ...state,
                sponsor_service_details: action.data
            }
        }
        default:
            return { ...state }
    }
}

export default sponsorReducer