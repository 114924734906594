// Library


// Component
import Button from '@mui/material/Button'
import { getTransWord } from "../../../helpers";


 const ButtonOption = ({ iconPath, desc, active, value, onClick }) => {
    
    const handleClick = () => {
        if (onClick) return onClick(value)
        return value
    }

    return (
        <Button
            variant="filled"
            sx={{
                textTransform: 'initial',
                borderRadius: '22px',
                background: 'white',
                padding: '9px 10px',
            }}
            onClick={handleClick}
        >
            <div className="grid grid-cols-5 gap-2">
                <div className="col-span-2 self-center">
                    <img src={`${iconPath}`} className="mx-auto w-28" />
                </div>
                <div className="text-left text-black self-center col-span-3 text-xs whitespace-pre-line" dangerouslySetInnerHTML={{__html: getTransWord(desc)}}></div>
            </div>
        </Button>
    )
}

export default ButtonOption