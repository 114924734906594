import {createContext, useReducer} from 'react'
import configData from '../config.js'

const initialAppData = { 
    id: null,
    page: 'home'
}

const getProductName = (id)=>{    
    fetch(`${configData.dashboard_api_endpoint}/incent/product/${id}`, {
        headers: {
          'Content-Type': 'application/json',
        },
    })
    .then(res => res.json())
    .then(
        (result) => {
            console.log('fetch product result: ', result)
        }
    )
}

const appDataReducer = (state, action) => {
    switch (action.type) {
        case 'set_id': 
            if (action.value === state?.id){
                console.log('Found same wor. ')
            }else{
                console.log('call api ')
                // getProductName(action.value)
                return {
                    id: action.value
                }
            }
            break;
        default: {
            throw Error('Unknown action: ' + action.type);
        }
    }
}
export const AppDataContext = createContext(initialAppData)

export function AppDataProvider({ children }) {
    const [appData, appDataDispatch] = useReducer(appDataReducer, initialAppData);

    return (
      <AppDataContext.Provider value={{appData, appDataDispatch}}>
          {children}
      </AppDataContext.Provider>
    );
  }
