import { 
    SET_INCENT_STATUS,
    SET_INCENT_FORM_CONFIG,
    SET_INECNT_FILTER_FORM_DATA,
    SET_INECNT_PERSONAL_DETAIL_FORM_DATA,
    SET_INCENT_VERIFY_DATA,
} from '../types'

export const setIncentStatus = (incentStatus) => {
    return { type: SET_INCENT_STATUS, incentStatus }
}

export const setIncentFormConfig = (incentFormConfig) => {
    return { type: SET_INCENT_FORM_CONFIG, incentFormConfig }
}

export const setIncentFilterFormData = (incentFilterFormData) => {
    return { type: SET_INECNT_FILTER_FORM_DATA, incentFilterFormData }
}   

export const setIncentPersonalDetailFormData = (incentPersonalDetailFormData) => {
    return { type: SET_INECNT_PERSONAL_DETAIL_FORM_DATA, incentPersonalDetailFormData }
}   

export const setIncentVerifyData = (verifyData) => {
    return { type: SET_INCENT_VERIFY_DATA, verifyData }
}