// Library
import { forwardRef, useState, ChangeEvent, useEffect } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { FormControlLabel, Checkbox } from '@mui/material';

// Component
const CheckboxField = forwardRef(({ placeholder, type, onChange, onBlur, name, label, errorMsg, setValue, control, icon, defaultValue }, ref) => {
    const [checked, setChecked] = useState(false)
    const handleChange = (event: ChangeEvent) => {
        setChecked(event.target.checked)
        setValue(name, event.target.checked)
      };

    useEffect(()=> {
        if( defaultValue){
            setChecked(defaultValue)
        }
    }, [defaultValue])

    return <div className="px-4">
        <FormControlLabel
            sx={{ alignItems: 'flex-start' }}
            label={
                <>
                    <span className="text-black text-xs">{label}</span>
                    {errorMsg? <><br /><span className="text-red-500 text-xs">{errorMsg}</span></> : null}
                </>
            }
            control={
                <Checkbox
                    ref={ref}
                    sx={{
                        marginTop: -1,
                    }}
                    icon={<CheckCircleIcon />}
                    checkedIcon={<CheckCircleIcon style={{ color: "#15BE77" }} />}
                    checked={checked}
                    onChange={handleChange}
                    inputProps={{ 'aria-label': 'controlled' }}
                />
            }
        />
    </div>
    
});
export default CheckboxField