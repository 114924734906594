import "./container.scss"
import Icons from "../icons"

export default function Container(props){
    return (
        <>
            <div className="field-container w-full flex flex-col bg-white px-6 pb-4 pt-1">
                <div className="flex items-end">
                    {
                        props.icon? <Icons name={props.icon}/> : <></>
                    }
                   
                    {props.children}
                </div>
            </div>
        </>
    )
}
