const lang = {
    common: {
        redeemProduct: 'GET FREE PRODUCT',
        tnc: 'Privacy Policy',
        submit: "Submit",
    },

    home: {
        sponsor: 'Get Brands Sponsor this',
        getSponsor: 'Get Sponsor',
    },

    filter: {
        form: {
            title: 'Fill in your info to check your eligibility',
            age: {
                label: 'Age',
                desc: 'Age',
                error: {
                    required: 'Please enter your age'
                }
            },
            gender: {
                label: 'Gender',
                desc: 'Gender',
                error: {
                    required: 'Please select gender',
                },
                options: {
                    male: {
                        label: 'Male',
                        desc: null,
                        value: 'male',
                        name: 'male'
                    },
                    female: {
                        label: 'Female',
                        desc: null,
                        value: 'female',
                        name: 'female'
                    }
                }
            },
            hasChild: {
                label: 'Do you have children?',
                desc: 'Do you have children?',
                error: {
                    required: 'Please select',
                },
                options: {
                    yes: {
                        label: 'Yes',
                        desc: null,
                        value: 'yes',
                        name: 'yes'
                    },
                    no: {
                        label: 'No',
                        desc: null,
                        value: 'no',
                        name: 'no'
                    }
                }
            },
            childAge: {
                label: 'Age of your child',
                desc: 'Age of your child',
                error: {
                    requiredIf: 'Please enter the age of your child',                    
                },
            },
            submitBtn: "Check My Eligibility"
        }
    },

    brand: {
        congratulation: 'CONGRATULATION',
        description: 'You are eligible for the sponsor!<0 />You will get <1>{{productName}}</1> for free if you are interested in any services listed below.'
    },
    reg: {
        heading: "Almost Done",
        desc: "By completing the questionnaire, you will be gifted: \"{{productName}}\"",
        form: {
            name: {
                label: 'Name',
                desc: 'Please input your name.',
                error: {
                    required: 'Please enter your name'
                }
            },
            email: {
                label: 'Email',
                desc: 'Please input your email.',
                error: {
                    required: 'Please enter your email',
                    pattern: 'Incorrect email format'
                }
            },
            mobile: {
                label: 'Mobile Number',
                desc: 'Please input your mobile number.',
                error: {
                    required: 'Please enter your mobile number'
                }
            },
            interested: {
                label: 'Interested Service / Product',
                desc: 'What’s the service / product you are interested?',
                error: {
                    required: 'Please choose your interested Service / Product'
                },
                option1: {
                    label: 'Brand Showcase',
                    desc: '',
                },
                option2: {
                    label: 'Campaign Showcase',
                    desc: '',
                },
                option3: {
                    label: 'Passive Income',
                    desc: '',
                },
                option4: {
                    label: '90分鐘 任食邊爐 $50 現金券',
                    desc: '由 任食火鍋店 （沙田分店） 提供',
                }
            },
            opt_mkt: "To grant you the gift, you understand that we need to share your questionnaire answers with the brand/service provider and you may receive further promotional and marketing information by CloudRetail Pte Ltd or its partners.",
            error: {
                'registered': 'Already registered',
                'submitted': 'Form already submitted'
            },
            submit: "Submit",
        }
    },

    verify: {
        finalStep: 'Final Step',
        title: 'Enter 6-digit Verification code',
        description_all: 'Code is sent to {{email}} and {{phoneNumber}}. The code will expired in 10:00',
        description_email: 'Code is sent to {{email}}. This code will expired in 10:00',
        description_sms: 'Code is sent to {{phoneNumber}}. This code will expired in 10:00',
        inputVerifyCode: 'Enter Verification Code',
        sentTo: 'The code is sent to +{{ countryCode }} {{ phoneNumber }}',
        sentToMobile: 'The code is sent to {{mobile}}',
        resend: '重新傳送驗證碼',
        getYourProduct: 'Get Your Product',
    },

    kudos: {
        congrats: "Kudos!",
        dropping: "Your Products is being dispensed, if not <br> please use the following code"
    },

    sorry: {
        sorry: "Sorry",
        desc: "The redemption code is expired or verified. \n Please re-scan with a new code."
    },

    success: {
        congrats: "Kudos!",
        dropping: "You have already received the product for free. The vending machine is dispensing it now. Please wait a moment. ",
        useRedeem: "If there is still no response after 1 minute, you may use the following redemption code to redeem (valid for 4 hours)."
    },

    errors: {
        verifyCodeRequired: 'Please enter verification code',
        verifySmsCodeRequired: 'Please enter SMS verification code',
        verifyEmailCodeRequired: 'Please enter email verification code',
        verifyCodeExpiredOrVerified: 'Verification code is expired or verified',
        wrongVerifyCode: 'Wrong verification code',
        wrongSmsVerifyCode: 'Wrong SMS verification code',
        wrongEmailVerifyCode: 'Wrong email verification code',
        cannotResendVerifyCode: 'Resend verification failed',
    },

    waitList: {
        heading: "Join the waitlist",
        desc: "Fill in the below form and We will let you know when the products for you are ready",
        thankYouModal: {
            title: 'THANK YOU',
            desc: 'Thanks for joining us. We will let you know when the products for you are ready'
        }
    }
}

export default lang
