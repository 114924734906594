// Library
import { forwardRef, useState, useEffect } from "react";

// Component
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Container from "./Container.js";

import "./Select.css";

const CustomSelect = forwardRef(
    ({ placeholder, type, onChange, onBlur, name, label, options, setValue, errorMsg, defaultValue, icon }, ref) => {
        const [selected, setSelected] = useState('');

        const handleChange = (event: SelectChangeEvent) => {
            setValue(name, event.target.value)
            setSelected(event.target.value)
        }
        useEffect(()=> {
            if( defaultValue){
                setSelected(defaultValue)
            }
        }, [defaultValue])

        return (
            <>
                <Container label={label} errorMsg={errorMsg} icon={icon}>
                    <FormControl
                        error={!!errorMsg}
                        variant="standard"
                        className="w-full"
                    >
                        <InputLabel>{label}</InputLabel>

                        <Select
                            ref={ref} 
                            className="form-select text-black"
                            styles={{
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    border: !state.isFocused && 'none',
                                })
                            }}
                            label={label}
                            placeholder={label}
                            value={selected}
                            multiple={type==='multiselect'}
                            onChange={handleChange}
                            disableUnderline
                        >
                            {
                                options?.map((option) => (
                                    <MenuItem key={'select-'+name+option.name} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))
                            }
                        </Select>
                        <FormHelperText>{errorMsg}</FormHelperText>
                    </FormControl>
                </Container>
            </>
        );
    }
);
export default CustomSelect
// export default Select;
