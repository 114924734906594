// Library
import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useParams, Outlet } from 'react-router-dom'
import axios from 'axios'
import configData from '../config.js'
import { setIncentStatus } from '../redux/actions/incent'
import ThemeContainer from "../components/ThemeContainer"
import { UpdateLocaleByLang } from '../helpers'

export default function Main() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { id, lang } = useParams()
    const { incentStatus } = useSelector(state => state.incent)

    useEffect(() => {
        console.log('main use effect')
        UpdateLocaleByLang(lang)
        if (!incentStatus) {
            axios(`${configData.dashboard_api_endpoint}/incent/incent_form_submissions/${id}`).then(({data}) => {
                dispatch(setIncentStatus(data))
                switch(data?.status){
                    case 4:
                    case 2:
                        // TODO: check whether the verification code time ended
                        navigate(`/${lang}/${id}/verify`)
                        break
                    case 3:
                        navigate(`/${lang}/${id}/sorry`)
                        break
                    case 1:
                        navigate(`/${lang}/${id}/kudos`)
                        break
                    default:
                        navigate(`/${lang}/${id}`)
                        break
                }
            }).catch((err) => {
                console.log('fetch incent status fail: ', err)
            })
        }
    }, [incentStatus])

    return (
        <ThemeContainer >
            {incentStatus && <Outlet/>}
        </ThemeContainer>
    )
}
