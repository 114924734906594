import i18n from 'i18next';
import i18next from './i18n';
import configData from './config.js'

export function getTransWord (stringObj) {
    if(!stringObj)
        return null
    const lang = i18n.language
    return stringObj[lang] ?? stringObj['en']
}

export function UpdateLocaleByLang(lang) {
    switch(lang) {
        case 'zh':
            i18next.changeLanguage('zh-hk')
            break
        case 'sc':
            i18next.changeLanguage('zh-cn')
            break
        default:
            i18next.changeLanguage('en')
            break
    }
}

export function getProductImageUrl(id) {
    if (!id )
        return null
    return configData.product_image_url.replace('{product_id}', id)
}