// Library
import { createBrowserRouter } from 'react-router-dom'

// Pages
import Main from '../pages/Main'
import Home from '../pages/Home'
import PersonalDetails from '../pages/PersonalDetails'
import Filter from '../pages/Filter'
import Brand from '../pages/Brand'

import Verify from '../pages/Verify'
import Kudos from '../pages/Kudos'
import Sorry from '../pages/Sorry'
// import PrivacyPolicy from '../pages/PrivacyPolicy'
import WaitList from '../pages/WaitList'

export default createBrowserRouter([
    {
        path: '/:lang/:id/sorry',
        element: <Sorry />
    },
    {
        path: '/:lang/:id',
        element: <Main />,
        children: [
            {
                index: true,
                element: <Home />,
            },
            {
                path: '/:lang/:id/filter',
                element: <Filter />,
            },
            {
                path: '/:lang/:id/brand',
                element: <Brand />,
            },
            {
                path: '/:lang/:id/personal-details',
                element: <PersonalDetails />,
            },
            {
              path: '/:lang/:id/verify',
              element: <Verify />,
            },
            {
                path: '/:lang/:id/kudos',
                element: <Kudos />,
            },
            {
                path: '/:lang/:id/waitlist',
                element: <WaitList />,
            },
            // {
            //   path: '/:lang/privacy_policy',
            //   element: <PrivacyPolicy />,
            // },
        ],
    },
]);

