import { SET_SELECTED_SPONSOR, SET_SPONSOR_SERVICE_DETAILS } from '../types'

export const setSelectedSponsor = (selected_sponsor) => {
    return { type: SET_SELECTED_SPONSOR, selected_sponsor }
}
export const setSponsorServiceDetails = (sponsor_service_details) => {
    let data = null
    if(sponsor_service_details){
        data = sponsor_service_details.reduce((sponsor, service)=>{
            sponsor[service.incent_sponsor_id] = {...service.incent_sponsor};
            sponsor[service.incent_sponsor_id]['services'] = sponsor[service.incent_sponsor_id]['services'] ?? [];
            sponsor[service.incent_sponsor_id]['services'].push({
                id: service.id,
                description: {
                    en: service.description_en,
                    zh: service.description_zh_hk,
                    cn: service.description_zh_cn,
                },
                name: {
                    en: service.name_en,
                    zh: service.name_zh_hk,
                    cn: service.name_zh_cn,
                }
            })
            return sponsor
        }, {})
    }
    return { type: SET_SPONSOR_SERVICE_DETAILS, data }
}