// Library
import { map } from 'lodash'

// Component
import ButtonOption from './ButtonOption'

export default ({ name, options, multiple }) => {
    // TODO: support multi-select
    if (multiple) return

    return (
        <div className={`grid gap-4 grid-rows-${options.length}`}>
            { map(options, option => <ButtonOption className="row-span-1" {...option} />) }
        </div>
    )
}
