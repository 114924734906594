// Library
import { useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next'

// Component
import FormInput from "./elements/Input"
import FormSelect from "./elements/Select"
import Checkbox from "./elements/Checkbox"
import StyledLoadingButton from '../common/StyledLoadingButton'
import {getTransWord} from "../../helpers"
import { useCallback } from "react";


export default function Container(props) {
    const { register, handleSubmit, formState: { errors }, setValue, control, getValues } = useForm({shouldUnregister: false});
    const { t } = useTranslation('reg')

    const FormElements = useCallback(() => {
        let configData = props.formConfig
        var elements = configData?.map((element, i) => {
            var FormComponent
            switch (element.type) {
                case 'checkbox': 
                    FormComponent = Checkbox
                    break
                case 'multiselect':
                case 'select':
                    FormComponent = FormSelect
                    break;
                default:
                    FormComponent = FormInput
                    break
            } 

            const validationObj = {}
            element.validation.forEach((rule)=> {
                switch (rule.type) {
                    case 'pattern':
                        validationObj[rule.type] = {
                            value: rule.value,
                            message: getTransWord(rule?.error)
                        }
                        break
                    default:
                        validationObj[rule.type] = getTransWord(rule?.error)
                        break
                }
            })
            const optionsObj = []
            if (element.options) {
                element.options.forEach((option) => {
                    optionsObj.push({
                        'label': getTransWord(option.label),
                        'desc': getTransWord(option?.desc),
                        'value': option.value,
                        'name': option?.name
                    })
                })
            }

            return <FormComponent
                key={i}
                icon={element.icon}
                control={control}
                type={element.type}
                label={getTransWord(element.label)}
                placeholder={getTransWord(element.label)}
                // {...register(element.name, {validationObj})}
                {...register(element.name, validationObj)}
                options={optionsObj}
                name={element.name}
                setValue={setValue}
                defaultValue = {getValues(element.name)}
                errorMsg={errors[element.name]?.['message']}
            />
        })
        return elements
    },[props.formConfig, register, setValue, errors, control, getValues])
    return <div className='w-full'>
        <form onSubmit={handleSubmit(props.onSubmit)} className="space-y-4">
            <FormElements />
            <div className="text-center py-4">
                <StyledLoadingButton
                    loading={props.loading}
                    type="submit"
                >{ props?.submitBtnTitle || t('form.submit') }</StyledLoadingButton>
            </div>
        </form>
    </div>
}
