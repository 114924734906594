import { useMemo, useEffect, useLayoutEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { includes } from 'lodash'
import './themeContainer.scss'
import { setIncentStatus } from '../redux/actions/incent'
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios'
import configData from '../config';
import {CircularProgress, Stack} from '@mui/material';

export default function ThemeContainer(props) {
    const { t } = useTranslation('common')
    const location = useLocation()
    const navigate = useNavigate()
    const { lang, id } = useParams();
    const { incentStatus } = useSelector(state => state.incent)
    const dispatch = useDispatch()

    const enableBack = useMemo(() => {
        return !includes([
            `/${lang}/${id}/waitlist`,
            `/${lang}/${id}/verify`,
            `/${lang}/${id}/sorry`,
            `/${lang}/${id}/kudos`,
            `/${lang}/${id}`,
        ], location.pathname)
    }, [location.pathname])

    useEffect(() => {
        // for refetch incent status
        if(!incentStatus){ 
            axios(`${configData.dashboard_api_endpoint}/incent/incent_form_submissions/${id}`).then(({data}) => {
                dispatch(setIncentStatus(data))
            }).catch((err) => {
                console.log('fetch incent status fail: ', err)
            })
        }
    }, [incentStatus])
    
    return (
        <>
            <div className="px-6 pt-12 text-white pb-12 min-h-screen relative theme-background">
                {incentStatus? 
                <>
                {enableBack && <div className='backButton' onClick={()=> navigate(-1)}>
                    <span>{'<'}</span>
                </div>}
                {props.children}
                {props.hidePrivacy ??
                    <div className="absolute bottom-0 right-0 text-xs text-white mr-6 mb-3">
                        <a href={'/privacy_policy.html'} target="_tab">{t('tnc')}</a>
                    </div>
                }
                </>
                :
                <Stack alignItems="center" className="pt-24">
                    <CircularProgress size={100} className="circular-progress"/>
                </Stack>}
            </div>
        </>
    );
}
