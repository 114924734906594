const lang = {
    common: {
        redeemProduct: '獲取免費產品',
        tnc: '使用條款及私隱聲明',
        submit: '提交'
    },

    home: {
        sponsor: "透過品牌贊助<br>免費攞禮物",
        getSponsor: '搵人贊助',
    },

    filter: {
        form: {
            title: "首先填少少資料，\n睇吓合唔合資格攞禮物",

            age: {
                label: '年齡',
                desc: '年齡',
                error: {
                    required: '請輸入你的年齡'
                }
            },
            gender: {
                label: '性別',
                desc: '性別',
                error: {
                    required: '請選擇性別',
                },
                options: {
                    male: {
                        label: '男',
                        desc: null,
                        value: 'male',
                        name: 'male'
                    },
                    female: {
                        label: '女',
                        desc: null,
                        value: 'female',
                        name: 'female'
                    }
                }
            },
            hasChild: {
                label: '你有孩子嗎？',
                desc: '你有孩子嗎？',
                error: {
                    required: '請選擇',
                },
                options: {
                    yes: {
                        label: '有',
                    },
                    no: {
                        label: '沒有',
                    }
                }
            },
            childAge: {
                label: '你的孩子年齡是多少？',
                desc: '你的孩子年齡是多少？',
                error: {
                    requiredIf: '請輸入你孩子的年齡',                    
                },
            },
            submitBtn: "睇吓合唔合資格"
        }
    },

    brand: {
        congratulation: '恭喜你！',
        description: '你符合資格獲得贊助！<0 />如果你對下列任何服務有興趣，你可以免費獲得 <1>{{productName}}</1> 。',
    },

    reg: {
        heading: "即將完成",
        desc: "只需完成簡單問卷，即可免費獲得以下產品： {{productName}}",
        form: {
            name: {
                label: '姓名',
                desc: '請輸入你的姓名',
                error: {
                    required: '請輸入姓名'
                }
            },
            email: {
                label: '電郵',
                desc: '請輸入你的電郵地址',
                error: {
                    required: '請輸入你的電郵地址'
                }
            },
            mobile: {
                label: '電話號碼',
                desc: '填寫電話號碼，並收取認證碼',
                error: {
                    required: '請輸入電話號碼'
                }
            },
            interested: {
                label: '感興趣的服務／產品',
                desc: '請選擇你感興趣的服務／產品',
                error: {
                    required: '請選擇'
                },
                option1: {
                    label: '小學英文補習免費試堂',
                    desc: '由 英群補習社（上環分店） 提供',
                },
                option2: {
                    label: '小學中文補習免費試堂',
                    desc: '由 英群補習社（上環分店） 提供',
                },
                option3: {
                    label: '免費專業皮膚分析一次',
                    desc: '由 BeautyZ (沙田分店) 提供',
                },
                option4: {
                    label: '90分鐘 任食邊爐 $50 現金券',
                    desc: '由 任食火鍋店 （沙田分店） 提供',
                }
            },
            opt_mkt: "我們需要將你的問卷答案分享予品牌／服務供應商。如你不願意接受折扣或接收由 Gritus Limited 和其合作夥伴所發送有關我們產品及服務的市場推廣及促銷信息，請留空此方格。",
            error: {
                'registered': '較早前已完成註冊',
                'submitted': '較早前已提交問卷'
            },
            submit: "提交問卷",
        },
    },

    verify: {
        finalStep: '最後一步',
        title: '輸入6位數驗證碼。',
        description: '驗證碼已發送至{{phoneNumber}} 該驗證碼將在 10:00 後過期。',
        description_all: '驗證碼已發送至{{email}} 和 {{phoneNumber}} 該驗證碼將在 10:00 後過期。',
        description_email: '驗證碼已發送至{{email}} 該驗證碼將在 10:00 後過期。',
        description_sms: '驗證碼已發送至{{phoneNumber}} 該驗證碼將在 10:00 後過期。',
        inputVerifyCode: '輸入驗證碼',
        sentTo: '已傳送到 +{{ countryCode }} {{ phoneNumber }}',
        sentToMobile: '已傳送到 {{mobile}}',
        resend: '重新傳送驗證碼',
        getYourProduct: '領取你的產品',
    },

    kudos: {
        congrats: "恭喜！",
        dropping: "售賣機正在出貨<br>如果沒有反應，請使用以下兌換碼"
    },

    sorry: {
        sorry: "抱歉",
        desc: "兌換連結已經過期\n請重新掃描新的二維碼"
    },

    success: {
        congrats: "恭喜！",
        dropping: "你已經免費獲得產品，售賣機正在出貨，請稍等。 ",
        useRedeem: "如 1 分鐘後仍然沒有反應，可使用以下換領碼換領（有效期為 4 小時）"
    },

    errors: {
        verifyCodeRequired: '請輸入驗證碼',
        verifySmsCodeRequired: '請輸入短信驗證碼',
        verifyEmailCodeRequired: '請輸入電子郵件驗證碼',
        verifyCodeExpiredOrVerified: '驗證碼逾時或已驗證',
        wrongVerifyCode: '驗證碼錯誤',
        wrongSmsVerifyCode: '短信驗證碼錯誤',
        wrongEmailVerifyCode: '電子郵件驗證碼錯誤',
        cannotResendVerifyCode: '重新傳送驗證碼失敗',
    },

    waitList: {
        heading: "加入等候名單",
        desc: "抱歉，暫時找不到願意贊助你的品牌\n請填寫下面的表格\n當有合適你的免費優惠，我們會通知你",
        thankYouModal: {
            title: '謝謝你',
            desc: '感謝你加入我們，當有合適你的免費優惠，我們會通知你'
        }
    }
}

export default lang
