// Library
import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios"
import i18next from '../i18n';
import { useTranslation } from 'react-i18next'
import { mapValues, get } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { setIncentFilterFormData, setIncentFormConfig } from "../redux/actions/incent";
import { useForm } from "react-hook-form"
// Component
import ProductImage from "../components/ProductImage";
import FormInput from "../components/form/elements/Input";
import FormSelect from "../components/form/elements/Select";
import StyledLoadingButton from "../components/common/StyledLoadingButton";

import configData from "../config.js"
import { getProductImageUrl } from "../helpers";

export default function Filter() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState();
    const [showHasChild, setShowHasChild] = useState(false)
    const [showChildAge, setShowChildAge] = useState(false)
    const { t } = useTranslation('filter')
    const dispatch = useDispatch()
    const { incentStatus } = useSelector(state => state.incent)

    const ShowError = () => {
        if (error) return (<div className="text-red-500 text-center">{error}</div>)
        return (<></>)
    }

    let { lang, id } = useParams();

    const { register, unregister, handleSubmit, watch, formState: { errors }, setValue, control, getValues } = useForm({shouldUnregister: false});
    const watchHasChild = watch('has_child')
    const watchAge = watch('age')

    useEffect(()=>{
        console.log('watchAge', watchAge)
        
        if(!watchAge || watchAge < configData.min_has_child_age){
            unregister("has_child")
            setShowHasChild(false)
        }else{
            setShowHasChild(true)
        }
    }, [register, unregister, watchAge])

    useEffect(()=>{
        console.log('watchHasChild', watchHasChild)
        if(!watchHasChild){
            unregister("child_age")
        }
        setShowChildAge(watchHasChild)

    }, [register, unregister, watchHasChild])

    const onSubmit = async (data, event) => {
        const submitData = mapValues(data, (item)=>(
            item.value || item
        ))
        event.preventDefault()
        setLoading(true)
        dispatch(setIncentFilterFormData(submitData))
        navigate(`/${lang}/${id}/brand`)
    }

    return (
        <div className='reg space-y-4'> 
            <ProductImage productImage={getProductImageUrl(get(incentStatus, `redeem_product.id`, null))} />
            <div className="header text-black whitespace-pre-line">{t('form.title')}</div>
            <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
                <FormInput 
                    icon={null}
                    control={control}
                    type="number"
                    label={t('form.age.label')}
                    placeholder={t('form.age.desc')}
                    // {...register(element.name, {validationObj})}
                    {...register('age', {
                        required: t('form.age.error.required')
                    })}
                    name='age'
                    setValue={setValue}
                    defaultValue = {getValues('age')}
                    errorMsg={errors['age']?.['message']}
                    inputProps={{ min: 0 }}
                />
                <FormSelect 
                    control={control}
                    type='select'
                    label={t('form.gender.label')}
                    placeholder={t('form.gender.placeholder')}
                    // {...register(element.name, {validationObj})}
                    {...register('gender', {
                        required: t('form.gender.error.required')
                    })}
                    options={[
                        {
                            'label': t('form.gender.options.male.label'),
                            'desc': null,
                            'value': 'male',
                            'name': 'male'
                        },
                        {
                            'label': t('form.gender.options.female.label'),
                            'desc': null,
                            'value': 'female',
                            'name': 'female'
                        }
                    ]}
                    name={'gender'}
                    setValue={setValue}
                    defaultValue = {getValues('gender')}
                    errorMsg={errors['gender']?.['message']}
                />
                {
                    showHasChild &&
                    <FormSelect
                        control={control}
                        type='select'
                        label={t('form.hasChild.label')}
                        placeholder={t('form.hasChild.placeholder')}
                        // {...register(element.name, {validationObj})}
                        {...register('has_child', {
                            required: t('form.hasChild.error.required')
                        })}
                        options={[
                            {
                                'label': t('form.hasChild.options.yes.label'),
                                'desc': null,
                                'value': 1,
                                'name': 'yes'
                            },
                            {
                                'label': t('form.hasChild.options.no.label'),
                                'desc': null,
                                'value': 0,
                                'name': 'no'
                            }
                        ]}
                        name='has_child'
                        setValue={setValue}
                        defaultValue = {getValues('has_child')}
                        errorMsg={errors['has_child']?.['message']}
                    />
                }
                {
                    showChildAge && 
                    <FormInput 
                        control={control}
                        type='number'
                        label={t('form.childAge.label')}
                        placeholder={t('form.childAge.desc')}
                        // {...register(element.name, {validationObj})}
                        {...register('child_age', {
                            required:  t('form.childAge.error.requiredIf'),
                            valueAsNumber: true,
                            min: 0
                        })}
                        inputProps={{ min: 0 }}
                        name='child_age'
                        setValue={setValue}
                        defaultValue = {getValues('child_age')}
                        errorMsg={errors['child_age']?.['message']}
                    />
                }
                <div className="text-center py-4">
                    <StyledLoadingButton
                        loading={loading}
                        type="submit"
                    >{ t('form.submitBtn') }</StyledLoadingButton>
                </div>
            </form>
            <ShowError />
        </div>
    );
}
