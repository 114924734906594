import ThemeContainer from '../components/ThemeContainer';
import { useEffect, useLayoutEffect, useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import configData from '../config';
import axios from 'axios';
import i18next from '../i18n';
import { useTranslation } from 'react-i18next'
import "./Kudos.scss"
import { useSelector } from 'react-redux'

const Kudos = () => {
    const { t, i18n } = useTranslation('kudos')
    const { lang, id } = useParams();
    const [redeemCode, setRedeemCode] = useState(null)
    const { incentStatus } = useSelector(state => state.incent)

    // useEffect(() => {
    //     axios(`${configData.dashboard_api_endpoint}/incent/incent_form_submissions/${id}`)
    //     .then((response) => {
    //         console.log('incent status', response?.data)
    //         if (response?.data?.redeemCode){
    //             setRedeemCode(response.data.redeemCode)
    //         }
    //     })
    // }, [])
    return (
        // <ThemeContainer>
            <div className="flex flex-col Kudos justify-center absolute inset-0 p-8">
                <div className="text-center font-bold space-y-4">
                    <img src="/kudos.png"  alt="kudos" className="mx-auto m-4"/>
                    <span className="green-text block text-3xl">{t('congrats')}</span>
                    <span className="desc block text-black" dangerouslySetInnerHTML={{ __html: t('dropping') }}></span>
                    <div className="redeemcode w-full py-4">
                        <span className="green-text">{redeemCode || incentStatus?.redeemCode}</span>
                    </div>
                </div>
            </div>
        // </ThemeContainer>
    );
}
export default Kudos