import { useTranslation } from 'react-i18next'
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import StyledLoadingButton from '../common/StyledLoadingButton';
import "./PopupModal.scss";

const PopupModal = (props) => {
    const { t } = useTranslation('reg')
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 400,
        backgroundColor: "background.paper",
        // border: '2px solid #000',
        boxShadow: 24,
        padding: "32px 16px",
        width: "90%",
        border: "1px solid #F4F4F4",
        boxShadow: "12px 26px 50px rgba(90, 108, 234, 0.07)",
        borderRadius: "15px",
    };

    return (
        <Modal
            open={props.open}
            onClose={props.onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    {props.title}
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    {props.description}
                </Typography>
                <br/>
                {props.submitBtnTitle && <StyledLoadingButton
                    loading={props.loading}
                    type="submit"
                    onClick={props.submitBtnOnClick}
                >{props.submitBtnTitle}</StyledLoadingButton>}
            </Box>
        </Modal>
    );
};

export default PopupModal;
