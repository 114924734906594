// Library
import { useLayoutEffect, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from "react-router-dom";
import { Trans, useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { get, snakeCase } from 'lodash'
import axios from "axios"
// Component
import ProductImage from '../components/ProductImage'
import ButtonOptionGroup from '../components/form/elements/ButtonOptionGroup'
import configData from "../config.js"
import {getProductImageUrl} from "../helpers"

// Action
import { setSelectedSponsor, setSponsorServiceDetails } from '../redux/actions/sponsor'

import WaitList from "./WaitList"

export default function Brand() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { lang, id } = useParams()
    const { t, i18n } = useTranslation('brand')
    const { selected_sponsor, sponsor_service_details } = useSelector(state => state.sponsor)
    const { incentStatus, incentFilterFormData } = useSelector(state => state.incent)
    const [buttonOptions, setButtonOptions] = useState([])
    const [loading, setLoading] = useState(false)

    const [fetchedApi, setFetchedApi] = useState(false)
    // This buttonOptions is used to render the 3 buttons in the home page
    // Each button has an icon, description and onClick function
    // The onClick function is used to set the sponsor in the redux store
    // and navigate to the personal detail page

    const getIncentSponsorServices = () => {
        setLoading(true)
        let buttonOptionsConfig = []
        let sponsorServices = []
        axios.get(`${configData.dashboard_api_endpoint}/incent/incent_sponsor_services`, {params: {incentFormSubmissionId: id, ...incentFilterFormData}}).then((response) => {
            let serviceDatas = []
            let sponsorDatas = []
            
            // prepare the nested array of sponsor to sponsor service
            if(response?.data?.data){
                let services = response.data.data
                // services.forEach((serviceDetail) => {
                //     console.log('foreach serviceDetail', serviceDetail)
                //     serviceDetails[]
                // })
                // sponsorDatas = services.reduce((sponsor, service)=>{
                //     sponsor[service.incent_sponsor_id] = sponsor[service.incent_sponsor_id] ?? [];
                //     sponsor[service.incent_sponsor_id].push(service.incent_sponsor);
                //     return sponsor
                // })
                dispatch(setSponsorServiceDetails(services))
                setFetchedApi(true)
            }

        }).finally(() => {
            setLoading(false)
        })
    }
    const toggleSponsor = (sponsorId) => {  
        // for later support multiple sponsor
        // let newSponsor = (!selected_sponsor)? []: [...selected_sponsor]
        // if(newSponsor.includes(sponsorId)){
        //     newSponsor = newSponsor.filter((sponsor) => sponsor !== sponsorId)
        // }else{
        //     newSponsor.push(sponsorId)
        // }
        let newSponsor = [sponsorId]
        dispatch(setSelectedSponsor(newSponsor))
        navigate(`/${lang}/${id}/personal-details`)
    }
    useEffect(()=> {
        if(!incentFilterFormData) {
            navigate(`/${lang}/${id}`)
        }
    }, [incentFilterFormData])

    useLayoutEffect(() => {
        if(!loading){
            if(!fetchedApi){
                getIncentSponsorServices()
            }else{
                let buttonOptionsConfig = []
                Object.entries(sponsor_service_details).forEach(([data, sponsor]) => {
                    buttonOptionsConfig.push({
                        iconPath: sponsor?.logo,
                        desc: {
                            en: sponsor.name_en,
                            zh: sponsor.name_zh_hk,
                            cn: sponsor.name_zh_cn,
                        },
                        onClick: () => {
                            toggleSponsor(sponsor.id)
                        },
                    })
                })
                setButtonOptions(buttonOptionsConfig)
            }
        }
    }, [fetchedApi, loading])

    const productName = useMemo(() => {
        return get(incentStatus, `redeem_product.name_${snakeCase(i18n.language)}`, '')
    }, [incentStatus, i18n.language])


    return (
        <>
        {(!loading && fetchedApi) ?
            ((!buttonOptions || buttonOptions.length === 0)) ? <WaitList /> :
            <div className="Brand mx-auto max-w-md space-y-4">
                <ProductImage productImage={getProductImageUrl(get(incentStatus, `redeem_product.id`, null))} />
                <div className="header text-black">{ t('congratulation') }</div>
                <div className="description text-black leading-6 whitespace-pre-line text-xs">
                    <Trans
                        i18nKey="brand:description"
                        values={{ productName }}
                        components={[<br/>, <strong></strong>]}
                    />
                </div>

                <ButtonOptionGroup
                    type= 'button_options'
                    options={buttonOptions}
                />
            </div>
        : null}
        </>
    )
}
