// Library
import { useState, useMemo, useEffect, useRef } from "react"
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from "react-router-dom"
import { useSelector, useDispatch } from 'react-redux'
import axios from 'axios'
import i18n from 'i18next'
import { get } from 'lodash'

// Component
import AuthCode from 'react-auth-code-input';
import StyledLoadingButton from '../components/common/StyledLoadingButton'
import { setIncentStatus } from '../redux/actions/incent'
import PopupModal from '../components/common/PopupModal'


import './Verify.css';
import configData from '../config';

export default function Verify() {
    const AuthInputRef = useRef(null);
    const { lang, id } = useParams();
    const navigate = useNavigate()
    const { t } = useTranslation('verify')
    const [code, setCode] = useState();
    const [smsCode, setSmsCode] = useState();
    const [emailCode, setEmailCode] = useState();
    const [loading, setLoading] = useState();
    const [error, setError] = useState();
    const [smsCodeError, setSmsCodeError] = useState();
    const [emailCodeError, setEmailCodeError] = useState();
    const { incentStatus } = useSelector(state => state.incent)
    const { incentPersonalDetailFormData } = useSelector(state => state.incent)
    const dispatch = useDispatch()
    const [showThankYou, setShowThankYou] = useState(false)

    const phoneNumber = useMemo(() => {
        return get(incentPersonalDetailFormData, 'phone_number', null)
    }, [incentPersonalDetailFormData])

    const email = useMemo(() => {
        return get(incentPersonalDetailFormData, 'email', null)
    }, [incentPersonalDetailFormData])

    const resend = async () => {
        setLoading(true)
        try {
            await axios.post(`${configData.dashboard_api_endpoint}/incent/incent_form_submissions/${id}/verify/resend`)
        } catch (err) {
            setError(t('errors:cannotResendVerifyCode'))
        } finally {
            setLoading(false)
        }
    }

    if( lang && lang !== i18n.language){
        i18n.changeLanguage(lang).then(()=>{
            console.log('app language changed', i18n.language)
        })
    }

    const showError = () => {
        if (error) return (<div className="text-red-500">{error}</div>)
        return (<></>)
    }

    const showErrorMsg = (errorMsg) => {
        if (errorMsg) return (<div className="text-red-500">{errorMsg}</div>)
        return (<></>)
    }
    
    const handleOnChange = (res) => {
        setCode(res);
    }

    const handleAuthCodeOnChange = (type, res) => {
        switch(type){
            case 'email':
                setEmailCode(res);
                break;
            case 'phone':
                setSmsCode(res);
                break;   
            default:
                break;    
        }
    }

    const handleClose = () => {
        setShowThankYou(false)
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        setError(null)
        setSmsCodeError(null)
        setEmailCodeError(null)

        let error = false
        let data = {}
        if(incentStatus?.incent_form?.requires_phone_verification) {
            if(!smsCode){
                setSmsCodeError(t('errors:verifySmsCodeRequired'))
                error = true
                // return
            }else if(smsCode.length < 6){
                setSmsCodeError(t('errors:wrongSmsVerifyCode'))
                error = true
                // return
            }else{
                data['sms_code'] = smsCode
            }
        }

        if(incentStatus?.incent_form?.requires_email_verification) {
            if(!emailCode){
                setEmailCodeError(t('errors:verifyEmailCodeRequired'))
                error = true
                // return
            }else if(emailCode.length < 6){
                setEmailCodeError(t('errors:wrongEmailVerifyCode'))
                error = true
                // return
            }else{
                data['email_code'] = emailCode
            }
        }
        if(error){
            return
        }
        setLoading(true)
        try {
            await axios.post(`${configData.dashboard_api_endpoint}/incent/incent_form_submissions/${id}/verify`, data)
            if(incentStatus.status === 4){
                //is waitlist
                setShowThankYou(true)
            }else{
                dispatch(setIncentStatus(null))
                navigate(`/${lang}/${id}/kudos`)
            }
        } catch (err) {
            switch (err.response.status) {
                case 400:
                    setError(t('errors:wrongVerifyCode'))
                    break
                case 404:
                    setError(t('errors:verifyCodeExpiredOrVerified'))
                    navigate(`/${lang}/${id}/sorry`, { replace: true })
                    break
                default:
                    setError(err.message || err.data)
            }
        } finally {
            setLoading(false)   
            AuthInputRef.current?.clear()
        }
    }
  
    return (
        <>
            <div className="Verify mx-auto max-w-md">
                <div className="step text-black font-bold mt-4">{ t('finalStep') }</div>
                <div className="header text-black font-bold pr-10">{ t('title') }</div>
                <div className="description text-black text-xs font-bold pr-24">
                    { 
                        (
                            incentStatus?.incent_form?.requires_email_verification && 
                            incentStatus?.incent_form?.requires_phone_verification
                        )?
                        t('description_all', { phoneNumber: incentStatus?.phone_number, email: incentStatus?.email }) 
                        :                        
                            incentStatus?.incent_form?.requires_email_verification
                            ?
                                t('description_email', { email: incentStatus?.email }) 
                            :  
                                t('description_sms', { phoneNumber: incentStatus?.phone_number }) 
                    }
                </div>
                <form className="" onSubmit={handleSubmit}>
                    {
                        incentStatus?.incent_form?.requires_email_verification && 
                        <div className="py-4">
                            <span className="text-black font-bold text-xs">
                                Verification Code From Email
                            </span>
                            <AuthCode 
                                onChange={(res)=> {
                                    handleAuthCodeOnChange('email', res)
                                }} 
                                containerClassName='auth-container flex justify-between mx-auto'
                                inputClassName='auth-input text-black text-center min-w-0 p-4 rounded m-1'
                                ref={AuthInputRef}
                            />
                            { showErrorMsg(emailCodeError) }
                        </div>
                    }
                    {
                        incentStatus?.incent_form?.requires_phone_verification &&
                        <div className="py-4">
                            <span className="text-black font-bold text-xs">
                                Verification code from SMS
                            </span>
                            <AuthCode
                                onChange={(res)=> {
                                    handleAuthCodeOnChange('phone', res)
                                }} 
                                containerClassName='auth-container flex justify-between mx-auto'
                                inputClassName='auth-input text-black text-center min-w-0 p-4 rounded m-1'
                                ref={AuthInputRef}
                            />
                            { showErrorMsg(smsCodeError) }
                        </div>
                    }
                    { showError() }
                    <div className="text-center py-16">
                        <StyledLoadingButton
                            loading={loading}
                            veriant="contained"
                            type="submit"
                        >{ t('getYourProduct') }</StyledLoadingButton>
                    </div>
                </form>
            </div>
            <PopupModal 
                open={showThankYou}
                title={t('waitList:thankYouModal.title')}
                description={t('waitList:thankYouModal.desc')}
            /> 
        </>
    );
}
