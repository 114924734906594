// Component
import LoadingButton from '@mui/lab/LoadingButton'
import CircularProgress from '@mui/material/CircularProgress'
import { styled } from '@mui/material/styles'

// make the `loading button` be generic
// reference: https://mui.com/material-ui/customization/how-to-customize/#2-reusable-component
const ButtonStyle = styled(LoadingButton) (() => ({
    display: 'block',
    padding: '0.75rem 1.5rem',
    margin: '0 auto',
    fontWeight: 'bold',
    fontSize: '17px',
    background: 'linear-gradient(98.81deg, #53E88B -0.82%, #15BE77 101.53%)',
    minWidth: '12rem',
    borderRadius: '15px',
    '&.Mui-disabled': {
        background: 'rgba(0, 0, 0, 0.12)',
    }
}))

export default (props) => (
    <ButtonStyle
        loadingIndicator={(<CircularProgress color="inherit" size={26} />)}
        sx={{
            textTransform: 'initial',
            color: 'white',
        }}
        {...props}
    />
)
