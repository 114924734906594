import { 
    SET_INCENT_STATUS,
    SET_INCENT_FORM_CONFIG,
    SET_INECNT_FILTER_FORM_DATA,
    SET_INECNT_PERSONAL_DETAIL_FORM_DATA,
    SET_INCENT_VERIFY_DATA,
} from '../types'

const initialState = {
    incentStatus: null,
    incentFormConfig: null,
    incentFilterFormData: null,
    incentPersonalDetailFormData: null,
    incentVerifyData: null,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_INCENT_STATUS:
            return {
                ...state,
                incentStatus: action.incentStatus,
            }
        case SET_INCENT_FORM_CONFIG:
            return {
                ...state,
                incentFormConfig: action.incentFormConfig,
            }
        case SET_INECNT_FILTER_FORM_DATA:
            return {
                ...state,
                incentFilterFormData: action.incentFilterFormData,
            }
        case SET_INECNT_PERSONAL_DETAIL_FORM_DATA:
            return {
                ...state,
                incentPersonalDetailFormData: action.incentPersonalDetailFormData,
            }
        case SET_INCENT_VERIFY_DATA:
            return {
                ...state,
                incentVerifyData: action.incentVerifyData,
            }
        default:
            return { ...state }
    }
}
