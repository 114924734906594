const lang = {
    common: {
        redeemProduct: '获取免费产品',
        tnc: '使用条款及私隐声明',
        submit: '提交'
    },

    home: {
        sponsor: "透过品牌赞助<br>免费攞礼物",
        getSponsor: '搵人赞助',
    },

    filter: {
        form: {
            title: "首先填少少资料，\n睇吓合唔合资格攞礼物",

            age: {
                label: '年龄',
                desc: '年龄',
                error: {
                    required: '请输入你的年龄'
                }
            },
            gender: {
                label: '性别',
                desc: '性别',
                error: {
                    required: '请选择性别',
                },
                options: {
                    male: {
                        label: '男',
                        desc: null,
                        value: 'male',
                        name: 'male'
                    },
                    female: {
                        label: '女',
                        desc: null,
                        value: 'female',
                        name: 'female'
                    }
                }
            },
            hasChild: {
                label: '你有孩子吗？ ',
                desc: '你有孩子吗？ ',
                error: {
                    required: '请选择',
                },
                options: {
                    yes: {
                        label: '有',
                    },
                    no: {
                        label: '没有',
                    }
                }
            },
            childAge: {
                label: '你的孩子年龄是多少？ ',
                desc: '你的孩子年龄是多少？ ',
                error: {
                    requiredIf: '请输入你孩子的年龄',                    
                },
            },
            submitBtn: "睇吓合唔合资格"
        }
    },

    brand: {
        congratulation: '恭喜你！ ',
        description: '你符合资格获得赞助！ <0 />如果你对下列任何服务有兴趣，你可以免费获得 <1>{{productName}}</1> 。 ',
    },

    reg: {
        heading: "即将完成",
        desc: "只需完成简单问卷，即可免费获得以下产品： {{productName}}",
        form: {
            name: {
                label: '姓名',
                desc: '请输入你的姓名',
                error: {
                    required: '请输入姓名'
                }
            },
            email: {
                label: '电邮',
                desc: '请输入你的电邮地址',
                error: {
                    required: '请输入你的电邮地址'
                }
            },
            mobile: {
                label: '电话号码',
                desc: '填写电话号码，并收取认证码',
                error: {
                    required: '请输入电话号码'
                }
            },
            interested: {
                label: '感兴趣的服务／产品',
                desc: '请选择你感兴趣的服务／产品',
                error: {
                    required: '请选择'
                },
                option1: {
                    label: '小学英文补习免费试堂',
                    desc: '由 英群补习社（上环分店） 提供',
                },
                option2: {
                    label: '小学中文补习免费试堂',
                    desc: '由 英群补习社（上环分店） 提供',
                },
                option3: {
                    label: '免费专业皮肤分析一次',
                    desc: '由 BeautyZ (沙田分店) 提供',
                },
                option4: {
                    label: '90分钟 任食边炉 $50 现金券',
                    desc: '由 任食火锅店 （沙田分店） 提供',
                }
            },
            opt_mkt: "我们需要将你的问卷答案分享予品牌／服务供应商。如你不愿意接受折扣或接收由 Gritus Limited 和其合作伙伴所发送有关我们产品及服务的市场推广及促销信息，请留空此方格。",
            error: {
                'registered': '较早前已完成注册',
                'submitted': '较早前已提交问卷'
            },
            submit: "提交问卷",
        },
    },

    verify: {
        finalStep: '最后一步',
        title: '输入6位数验证码。 ',
        description: '验证码已发送至{{phoneNumber}} 该验证码将在 10:00 后过期。 ',
        description_all: '验证码已发送至{{email}} 和 {{phoneNumber}} 该验证码将在 10:00 后过期。 ',
        description_email: '验证码已发送至{{email}} 该验证码将在 10:00 后过期。 ',
        description_sms: '验证码已发送至{{phoneNumber}} 该验证码将在 10:00 后过期。 ',
        inputVerifyCode: '输入验证码',
        sentTo: '已传送到 +{{ countryCode }} {{ phoneNumber }}',
        sentToMobile: '已传送到 {{mobile}}',
        resend: '重新传送验证码',
        getYourProduct: '领取你的产品',
    },

    kudos: {
        congrats: "恭喜！",
        dropping: "售卖机正在出货<br>如果没有反应，请使用以下兑换码"
    },

    sorry: {
        sorry: "抱歉",
        desc: "兑换连结已经过期\n请重新扫描新的二维码"
    },

    success: {
        congrats: "恭喜！",
        dropping: "你已经免费获得产品，售卖机正在出货，请稍等。 ",
        useRedeem: "如 1 分钟后仍然没有反应，可使用以下换领码换领（有效期为 4 小时）"
    },

    errors: {
        verifyCodeRequired: '请输入验证码',
        verifyEmailCodeRequired: '请输入电子邮箱验证码',
        verifySmsCodeRequired: '请输入短讯验证码',
        verifyCodeExpiredOrVerified: '验证码逾时或已验证',
        wrongVerifyCode: '验证码错误',
        wrongSmsVerifyCode: '短讯验证码错误',
        wrongEmailVerifyCode: '电子邮箱验证码错误',
        cannotResendVerifyCode: '重新传送验证码失败',
    },

    waitList: {
        heading: "加入等候名单",
        desc: "抱歉，暂时找不到愿意赞助你的品牌\n请填写下面的表格\n当有合适你的免费优惠，我们会通知你",
        thankYouModal: {
            title: '谢谢你',
            desc: '感谢你加入我们，当有合适你的免费优惠，我们会通知你'
        }
    }
}

export default lang